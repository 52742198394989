* {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p-datatable .p-datatable-thead > tr > th {
  font-size: 0.85rem;
  border: 1px solid #e9ecef;
}

.p-datatable .p-datatable-header {
  padding: 1rem 1rem;
  margin: 1rem 0;
  border: 1px solid #e9ecef;
}

.p-field {
  margin-bottom: 2rem;
}
