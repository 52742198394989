.customised-timeline-card .p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(odd) .p-timeline-event-opposite {
    margin-top: 15px;
}

.customised-timeline-card .p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(even) .p-timeline-event-opposite {
    margin-top: 15px;
}

.customised-timeline-card .p-card.p-component {
    margin: 10px;
}